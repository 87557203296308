<script lang="ts">
  import { Spinner } from "$lib/components";

  export let promise: Promise<unknown> = Promise.resolve();
  export let disabled: boolean = false;
  export let showSpinner: boolean = false;

  $: loading = updateLoading(promise);

  function updateLoading(promise: Promise<unknown>) {
    promise.then(() => {
      loading = false;
    });
    return true;
  }
</script>

<button
  class="btn btn-primary"
  class:disabled={loading || showSpinner || disabled}
  disabled={loading || showSpinner || disabled}
  type="button"
  on:click
  {...$$restProps}
>
  <div class="invisible">
    <Spinner size="sm" noFlex />
  </div>
  <slot />
  <div class:invisible={!loading && !showSpinner}>
    <Spinner size="sm" noFlex />
  </div>
</button>

<style>
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
</style>
